import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  ConversationLayoutComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/conversation-layout/conversation-layout.component";
import {
  ContactItemComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/contact-item/contact-item.component";
import {
  SearchContactComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/search-contact/search-contact.component";
import {
  ChatWidgetHeaderComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/header/chat-widget-header/chat-widget-header.component";
import {openRoomByIdRequestStart} from "@revolve-app/app/core/features/chat/store/rooms/rooms.actions";
import {Store} from "@ngrx/store";
import {selectIsLoggedIn, selectMeAccount} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";
import {selectSelectedRoom} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";
import {
  selectIpSession
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";

@Component({
  selector: 'app-conversation-widget',
  standalone: true,
  imports: [
    ConversationLayoutComponent,
    ContactItemComponent,
    SearchContactComponent,
    ChatWidgetHeaderComponent
  ],
  templateUrl: './conversation-widget.component.html'
})
export class ConversationWidgetComponent implements OnInit, OnChanges{


  @Input()chatId: string = '';


  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(openRoomByIdRequestStart({id: this.chatId}))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['chatId'] && changes['chatId'].currentValue){
      this.store.dispatch(openRoomByIdRequestStart({id: this.chatId}))
    }
  }


}
